<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input
                  @change="onQuerySubmit"
                  v-model="query.enterprise_name"
                  placeholder="企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="运维人员">
                <el-select
                  v-model="query.user_name"
                  placeholder="运维人员"
                  clearable
                >
                  <el-option
                    v-for="item in res.user"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="query.type" placeholder="状态" clearable>
                  <el-option label="待审核" :value="1"></el-option>
                  <el-option label="待配置" :value="2"></el-option>
                  <el-option label="待出库" :value="3"></el-option>
                  <el-option label="已完成" :value="4"></el-option>
                  <el-option label="审核不过" :value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker
                  v-model="dayValue"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit"
                  >查询</el-button
                >
                <el-button type="primary" @click="exportExcell">导出</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData.data" stripe style="width: 100%">
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.state === 2 &&
                        scope.row.status === 1
                        
                    "
                    class="light-brown"
                    >待配置</span
                  >
                  <span
                    v-if="
                      scope.row.state === 2 &&
                        scope.row.status === 2
                        
                    "
                    class="light-brown"
                    >待出库</span
                  >
                  <span
                    v-if="
                      scope.row.state === 2 &&
                        scope.row.status === 3
                        
                    "
                    class="light-green"
                    >已完成</span
                  >
                  <span
                    v-if="
                    scope.row.state == 1
                    "
                    class="light-brown"
                    >待审核</span
                  >
                  <span
                    v-if="scope.row.state === 3"
                    class="light-gray"
                    >审核不通过</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="enterprise_name"
                label="企业名称"
                min-width="180"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="equipment_name"
                label="排口名称"
                min-width="180"
              >
              </el-table-column>
              <el-table-column prop="name" label="试剂类型"> </el-table-column>
              <el-table-column
                label="排口试剂限额"
                min-width="280"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.reagent_total"
                    :key="index"
                  >
                    {{ item.name + ": " + item.total + item.unit + ";" }}
                    <span v-if="index % 2 === 1">
                      <br />
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="需求试剂情况"
                min-width="280"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.list"
                    :key="index"
                    :class="item.status ? 'red' : ''"
                  >
                    {{ item.name + ": " + item.total + "mL;" }}
                    <span v-if="index % 2 === 1">
                      <br />
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="need_at" label="需求时间" min-width="180">
              </el-table-column>
              <el-table-column
                prop="created_at"
                label="创建时间"
                min-width="180"
              >
              </el-table-column>
              <el-table-column prop="user_name" label="运维人员">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="reagentDatail(scope.row)"
                    type="text"
                    size="small"
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteItem(scope.row)"
                    v-if="permission.apiReagentApplyDelete"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          background
          :page-size="10"
          @current-change="changeCurrent"
          :current-page="query.page"
          :total="tableData.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  apiReagentApplyList,
  apiOutletCondition,
  apiReagentApplyDelete,
  apiReagentExport
} from "src/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      loading: false,
      tableData: {},
      res: {},
      query: {
        enterprise_name: "",
        user_name: "",
        start_time: "",
        end_time: "",
        type: "",
        page: 1,
        logotype: "maintainReagent"
      },
      dayValue: ""
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5("query")));
    if (query === null) {
      _this.query = _this.query;
    } else if (query.logotype === "maintainReagent") {
      _this.query = query;
      _this.dayValue = [query.start_time, query.end_time];
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    //获取运维人员数据
    apiOutletCondition()
      .then(lists => {
        _this.res = lists;
      })
      .catch(err => {
        _this.error(err);
      });
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: state => state.url,
    permission: state => state.permission,
    config: state => state.config
  }),
  watch: {
    $route: "getApplyList"
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询试剂记录列表
    onQuerySubmit() {
      this.query.start_time = this.dayValue[0];
      this.query.end_time = this.dayValue[1];
      this.query.page = 1;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&enterprise_name=" +
        this.query.enterprise_name +
        "&user_name=" +
        this.query.user_name +
        "&type=" +
        this.query.type +
        "&start_time=" +
        this.query.start_time +
        "&end_time=" +
        this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getApplyList();
      } else {
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&enterprise_name=" +
        this.query.enterprise_name +
        "&user_name=" +
        this.query.user_name +
        "&type=" +
        this.query.type +
        "&start_time=" +
        this.query.start_time +
        "&end_time=" +
        this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getApplyList();
      } else {
        this.$router.replace(route);
      }
    },
    //获取试剂记录列表数据
    getApplyList() {
      const _this = this;
      _this.loading = true;
      apiReagentApplyList(_this.query)
        .then(res => {
          _this.loading = false;
          _this.tableData = res;
        })
        .catch(err => {
          _this.loading = false;
          _this.error(err);
        });
    },
    //删除试剂记录
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        apiReagentApplyDelete({ id: item.id })
          .then(res => {
            _this.$message({
              message: "删除成功",
              type: "success"
            });
            _this.onQuerySubmit();
          })
          .catch(err => {
            _this.error(err);
          });
      });
    },
    // 导出
    exportExcell() {
      const _this = this;
      apiReagentExport(_this.query)
        .then(res => {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = _this.config.storage + res;
          a.download = "试剂记录表.xls";
          a.click();
          URL.revokeObjectURL(a.href);
        })
        .catch(err => {
          if (err.message === "没有操作权限") {
            return;
          } else {
            _this.$message.error(err.message);
          }
        });
    },
    //跳转试剂详情页
    reagentDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/maintain/reagent-detail/" + item.id);
    },
    //错误信息提示
    error(err) {
      if (err.message === "没有操作权限") {
        return;
      } else {
        this.$message.error(err.message);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
  position: relative;
  margin-top: 7px;
}
.light-green {
  color: #67c23a;
}
.light-brown {
  color: #e6a23c;
}
.light-gray {
  color: #909399;
}
.red {
  color: red;
}
</style>
